<template>
  <blank-layout>
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <logo class="mx-auto max-h-40 w-auto" />
      <h2
        class="mt-6 text-center text-3xl leading-9 text-gray-900"
      >
        Forgotten Password
      </h2>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 border border-gray-200">
        <a-alert
          v-if="error"
          type="error"
        >
          {{ error }}
        </a-alert>
        <a-alert
          v-if="completed"
          type="success"
        >
          <strong>Thank You!</strong> If there is an active account with that
          email address we will send out an email with instructions on how to
          reset your password.
        </a-alert>
        <div v-else>
          <a-form @submit="onSubmit">
            <div>
              <a-label
                for="email"
                :error="$v.email.$errors.length > 0"
              >
                Email Address
              </a-label>
              <div class="mt-1 rounded-md shadow-sm">
                <a-input
                  id="email"
                  v-model="email"
                  type="email"
                  :readonly="loading"
                  :error="$v.email.$errors.length > 0"
                  required
                />
                <div v-if="$v.email.$errors.length > 0">
                  <a-help error>
                    A valid Email Address is required.
                  </a-help>
                </div>
              </div>
            </div>

            <div class="mt-12">
              <span class="block w-full rounded-md shadow-sm">
                <a-button
                  :loading="loading"
                  class="w-full"
                  @click="onSubmit"
                >Submit</a-button>
              </span>
            </div>

            <div class="mt-6 flex justify-center">
              <div class="text-sm leading-5">
                Have an account?
                <router-link
                  :to="{
                    name: 'auth',
                    params: { conference: current }
                  }"
                  class="font-medium text-gray-700 underline focus:outline-none focus:underline transition ease-in-out duration-150"
                >
                  Sign In
                </router-link>
              </div>
            </div>
          </a-form>
        </div>
      </div>
    </div>
  </blank-layout>
</template>

<script>
import { join } from '@/utils/lodash';
import Logo from '@/components/layout/header/logo.vue';
import BlankLayout from '@/layouts/blank.vue';
import { mapActions, mapGetters } from 'vuex';
import { VuelidateMixin } from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
export default {
  components: {
    Logo,
    BlankLayout
  },
  mixins: [VuelidateMixin],
  data: () => ({
    email: '',
    loading: false,
    error: null,
    completed: false
  }),
  validations: {
    email: { required, email }
  },
  computed: {
    ...mapGetters('auth', ['hasToken']),
    ...mapGetters('conferences', ['current'])
  },
  watch: {
    current() {
      this.onVerifyGuest();
    },
    hasToken() {
      this.onVerifyGuest();
    }
  },
  methods: {
    ...mapActions('auth', ['resetEmail']),
    onVerifyGuest() {
      if (this.current && this.hasToken) {
        this.$router.replace({
          name: 'dashboard',
          params: { conference: this.current }
        });
      }
    },
    async onSubmit() {
      this.$v.$reset();
      this.$v.$touch();

      if (this.$v.$error) {
        return;
      }

      this.loading = true;
      this.error = null;

      try {
        await this.resetEmail({
          email: this.email
        });

        this.completed = true;
      } catch ({
        response: {
          data: { errors }
        }
      }) {
        this.error = join(errors, '<br />');
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>